import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import blogImg from "../assets/images/insights/img-1.png";

export const BlogDetail = () => {
  const { id } = useParams();

  const [newsList, setNewsList] = useState(null);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    fetch(`https://webbitech.co.in/PHP-BETA/vista/api/get-blog/`)
      .then((response) => response.json())
      .then((json) => setPosts(json));
  }, []);

  useEffect(() => {
    fetch(`https://webbitech.co.in/PHP-BETA/vista/api/get-blog/${id}`)
      .then((response) => response.json())
      .then((json) => setNewsList(json));
  }, [id]);

  if (!newsList || posts.length === 0) return <div>Loading...</div>;

  // Exclude the current news item from the list
  const filteredPosts = posts.filter((post) => post.id !== parseInt(id));

  const totalPages = Math.ceil(filteredPosts.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const currentPosts = filteredPosts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Function to remove <p> tags from content
  const removePTags = (htmlString) => {
    return htmlString.replace(/<\/?p>/g, "");
  };

  const cleanedDescription = removePTags(newsList.description);

  return (
    <div>
      <Header />

      {/* Sub banner */}
      <div className="sub_banner">
        <div className="container">
          <div className="banner_content_desc">
            <h5 className="banner_subtitle">Blog</h5>
            <h1 className="banner_title">{newsList.title}</h1>
            <div className="breadcrumb_detail">
              <div className="breadcrumb_box_grey">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Blog
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog_detail_inner">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-7">
              <div className="blog_img">
                <img src={newsList.image_url} alt="" />
              </div>
              <div className="card_body">
                <div className="blog_detail">
                  <div className="row gy-4 gx-0 align-items-center">
                    <div className="col-lg-12">
                      <ul className="blog_box_date">
                        <li>{newsList.news_date}</li>
                        <li>{newsList.author}</li>
                      </ul>
                    </div>
                    <div className="col-lg-12">
                      <h2 className="blog_title">{newsList.title}</h2>
                    </div>
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: cleanedDescription,
                  }}></p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="latest_blog_list">
                <div className="row gy-4">
                  {currentPosts.map((item) => (
                    <div className="col-lg-12" key={item.id}>
                      <div className="latest_blog_box">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="card_img">
                              <img src={item.image_url} alt={item.title} />
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="card_body">
                              <h2 className="card_title">{item.title}</h2>
                              <p className="card_text">
                                {item.short_description}
                              </p>
                              <p className="card_link mb-0">
                                <Link
                                  to={`/Blog/${item.id}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                  className="card_link">
                                  Read More{" "}
                                  <i className="bi bi-chevron-right"></i>
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end mt-5">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}>
                        <button
                          className="page-link prev"
                          onClick={() => handlePageChange(currentPage - 1)}>
                          <i className="bi bi-chevron-left"></i>
                        </button>
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${
                            currentPage === i + 1 ? "active" : ""
                          }`}>
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}>
                        <button
                          className="page-link next"
                          onClick={() => handlePageChange(currentPage + 1)}>
                          <i className="bi bi-chevron-right"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
