import aimlImg1 from "../../assets/images/ai-ml-data-mission/data-analytics.webp";
import aimlImg2 from "../../assets/images/ai-ml-data-mission/predictive-analytics.webp";
import aimlImg3 from "../../assets/images/ai-ml-data-mission/big-data-solution.webp";
import aimlImg4 from "../../assets/images/ai-ml-data-mission/data-prepration.webp";
import aimlImg5 from "../../assets/images/ai-ml-data-mission/natural-languaging.webp";
import aimlImg6 from "../../assets/images/ai-ml-data-mission/computer-vision.webp";
import aimlImg7 from "../../assets/images/ai-ml-data-mission/automated-inspection.webp";
import aimlImg8 from "../../assets/images/ai-ml-data-mission/machine-vision.webp";
import aimlImg9 from "../../assets/images/ai-ml-data-mission/smart-camera.webp";
import aimlImg10 from "../../assets/images/ai-ml-data-mission/pc-based-vision.webp";
import aimlImg11 from "../../assets/images/ai-ml-data-mission/vision-SW.webp";
import aimlImg12 from "../../assets/images/ai-ml-data-mission/lighting-optics.webp";
import { Home } from "../../pages/Home";

export const AIMLComputerVision = [
  {
    id: "data-analytics-and-machine-learning",
    title: "Data Analytics and Machine Learning",
    text: "VISTA, offers cutting-edge solutions to help businesses make informed decisions based on data analysis. By utilizing advanced algorithms and predictive modeling, we identify patterns and trends in large sets of data, allowing companies to optimize their operations and improve efficiency. ",
    path: "/",
    img: aimlImg1,
    alt: "Data Analytics and Machine Learning",
  },
  {
    id: "predictive-analytics",
    title: "Predictive Analytics",
    text: "We provide cutting-edge solutions to help businesses make informed decisions based on data analysis. By utilizing advanced algorithms and machine learning techniques, VISTA can accurately forecast trends and patterns in various industries. ",
    path: "/",
    img: aimlImg2,
    alt: "Predictive Analytics",
  },
  {
    id: "big-data-solutions",
    title: "Big Data Solutions",
    text: "We help businesses manage and analyze large amounts of data to make better decisions. Our services include data storage, processing, and visualization tools to help companies make sense of their data. VISTA's technology is cutting-edge and can help businesses stay competitive in today's data-driven world.",
    path: "/",
    img: aimlImg3,
    alt: "Big Data Solutions",
  },
  {
    id: "data-preparation-and-management",
    title: "Data Preparation and Management",
    text: "With a focus on ensuring data accuracy and efficiency  VISTA offers a wide range of solutions to help businesses effectively organize and make sense of their data. From data cleansing and enrichment to data integration and analysis  VISTA is dedicated to providing top-notch services that meet the unique needs of each client. ",
    path: "/",
    img: aimlImg4,
    alt: "Data Preparation and Management",
  },

  {
    id: "natural-language-processing-nlp",
    title: "Natural language processing ",
    text: "With a strong focus on NLP  VISTA excels in providing cutting-edge solutions and services that leverage the power of language processing. Whether it's developing algorithms for sentiment analysis or creating chatbots for customer service  Through our work businesses can optimize their processes streamline their operations  and stay ahead of the competition in today's fast-paced and data-driven marketplace",
    path: "/",
    img: aimlImg5,
    alt: "Natural language processing ",
  },
  {
    id: "computer-vision",
    title: "Computer Vision",
    text: "To improve the efficacy and efficiency of business operations, VISTA provides cutting-edge computer vision services.  ",
    path: "/",
    img: aimlImg6,
    alt: "Computer Vision",
  },
  {
    id: "automated-inspection",
    title: "Automated Inspection",
    text: "By implementing automated systems  VISTA streamlines the inspection process  providing accurate and reliable results in a fraction of the time it would take through manual inspection methods. ",
    path: "/",
    img: aimlImg7,
    alt: "Automated Inspection",
  },
  {
    id: "machine-vision",
    title: "Machine Vision",
    text: "Machine Vision is a cutting-edge technology that enables machines to process visual information in a manner similar to that of human beings. ",
    path: "/",
    img: aimlImg8,
    alt: "Machine Vision",
  },
  {
    id: "smart-camera-vision-system",
    title: "Smart Camera Vision System",
    text: "This system is used in a wide range of applications, from security surveillance to industrial automation, to improve efficiency and accuracy. ",
    path: "/",
    img: aimlImg9,
    alt: "Smart Camera Vision System",
  },
  {
    id: "pc-based-vision-system",
    title: "PC Based Vision System",
    text: "VISTA's PC Based Vision System service utilizes cutting-edge technology to assist businesses in enhancing their operational efficiency. ",
    path: "/",
    img: aimlImg10,
    alt: "PC Based Vision System",
  },
  {
    id: "vision-s-w-development",
    title: "Vision S/w Development",
    text: "Our team behind VISTA is dedicated to utilizing the latest technologies and techniques to develop custom software that meets the unique needs of each client. ",
    path: "/",
    img: aimlImg11,
    alt: "Vision S/w Development",
  },
  {
    id: "lighting-and-optics-design",
    title: "Lighting and Optics Design",
    text: "Lighting and optics design in VISTA involves creating efficient and effective lighting systems for various spaces. ",
    path: "/",
    img: aimlImg12,
    alt: "Lighting and Optics Design",
  },
];

<Home sectionId={AIMLComputerVision.id} />;
