import dataCollectionImg1 from "../../assets/images/data-collection/sensor-intergration.webp";
import dataCollectionImg2 from "../../assets/images/data-collection/data-logging-storage.webp";
import dataCollectionImg3 from "../../assets/images/data-collection/high-precision-mapping.webp";
import dataCollectionImg4 from "../../assets/images/data-collection/test-veichle-managment.webp";
import dataCollectionImg5 from "../../assets/images/data-collection/scenario-routing-planing.webp";
import dataCollectionImg6 from "../../assets/images/data-collection/data-annotation-labeling.webp";
import dataCollectionImg7 from "../../assets/images/data-collection/real-world-driving-collection.webp";
import dataCollectionImg8 from "../../assets/images/data-collection/simulation-data-genration.webp";
import dataCollectionImg9 from "../../assets/images/data-collection/data-quality-assurence.webp";
import dataCollectionImg10 from "../../assets/images/data-collection/requlatring.webp";
import dataCollectionImg11 from "../../assets/images/data-collection/data-analyses.webp";
import dataCollectionImg12 from "../../assets/images/data-collection/collabration-intergration.webp";
import dataCollectionImg13 from "../../assets/images/data-collection/machine-learning.webp";

export const DataCollection = [
  {
    id: "sensor-integration-and-calibration",
    title: "Sensor Integration and Calibration",
    text: "In order to improve data collection efficiency, VISTA is necessary for the smooth integration and precise calibration of sensors.",
    path: "/",
    img: dataCollectionImg1,
    alt: "Sensor Integration and Calibration",
  },
  {
    id: "data-logging-and-storage",
    title: "Data Logging and Storage",
    text: "Reliability and efficiency in gathering and storing data from multiple sources are hallmarks of VISTA's data logging expertise. ",
    path: "/",
    img: dataCollectionImg2,
    alt: "Data Logging and Storage",
  },
  {
    id: "high-precision-mapping",
    title: "High-Precision Mapping",
    text: "VISTA simplifies intricate mapping projects and creates opportunities for industries that depend on accurate data. With the most sophisticated tools, users can navigate with assurance and dependability.",
    path: "/",
    img: dataCollectionImg3,
    alt: "High-Precision Mapping",
  },
  {
    id: "test-vehicle-fleet-management",
    title: "Test Vehicle Fleet Management",
    text: "VISTA seeks to provide information about the dependability and efficiency of vehicles through the use of technology and sophisticated data gathering techniques. ",
    path: "/",
    img: dataCollectionImg4,
    alt: "Test Vehicle Fleet Management",
  },

  {
    id: "scenario-and-route-planning",
    title: "Scenario and Route Planning",
    text: "Researchers can simplify data collection with the help of VISTA, a Visual Interactive Scenario and Route Planning tool. ",
    path: "/",
    img: dataCollectionImg5,
    alt: "Scenario and Route Planning",
  },
  {
    id: "data-annotation-and-labeling",
    title: "Data Annotation and Labeling",
    text: "Annotating and labeling data sets is made efficient with VISTA. It improves accuracy and comprehensiveness by aiding in the organization and classification of data.",
    path: "/",
    img: dataCollectionImg6,
    alt: "Data Annotation and Labeling",
  },
  {
    id: "real-world-driving-data-collection ",
    title: "Real-World Driving Data Collection",
    text: "Our knowledge of driving behaviors and patterns in diverse environments is improved by the innovative service VISTA, which gathers real driving data. By examining this data, important insights into how people make decisions when driving are obtained, which helps to create transportation systems that are safer and more effective.",
    path: "/",
    img: dataCollectionImg7,
    alt: "Real-World Driving Data Collection",
  },
  {
    id: "simulation-data-generation",
    title: "Simulation Data Generation",
    text: "With its immersive and interactive, needs-specific simulations, VISTA is a state-of-the-art technology that is revolutionizing training. ",
    path: "/",
    img: dataCollectionImg8,
    alt: "Simulation Data Generation",
  },
  {
    id: "data-quality-assurance",
    title: "Data Quality Assurance",
    text: "To ensure accurate and trustworthy data for well-informed decision-making, data quality assurance is essential for organizations utilizing platforms such as VISTA. ",
    path: "/",
    img: dataCollectionImg9,
    alt: "Data Quality Assurance",
  },
  {
    id: "regulatory-compliance-and-reporting",
    title: "Regulatory Compliance and Reporting",
    text: "By helping businesses comply with laws and regulations, VISTA's Regulatory Compliance and Reporting services ensure compliance and lower the risk of penalties. ",
    path: "/",
    img: dataCollectionImg10,
    alt: "Regulatory Compliance and Reporting",
  },
  {
    id: "data-analysis-and-insights",
    title: "Data Analysis and Insights",
    text: "Businesses are equipped with useful information to make strategic decisions thanks to VISTA's Data Analysis and Insights services. ",
    path: "/",
    img: dataCollectionImg11,
    alt: "Data Analysis and Insights",
  },
  {
    id: "collaboration-and-integration-support",
    title: "Collaboration and Integration Support",
    text: "By working together with neighborhood organizations, volunteers close gaps and bring about positive change in neglected areas by addressing concerns like housing, healthcare, education, and poverty. ",
    path: "/",
    img: dataCollectionImg12,
    alt: "Collaboration and Integration Support",
  },
  {
    id: "machine-learning-model-training",
    title: "Machine Learning Model Training",
    text: "Organizations can streamline operations, make data-driven decisions, and effectively accomplish strategic goals with the aid of VISTA's machine learning model training services. ",
    path: "/",
    img: dataCollectionImg13,
    alt: "Machine Learning Model Training",
  },
];
