import projectManager from "../../assets/images/how-works/project-manager.svg";
import shareGuidlines from "../../assets/images/how-works/guidelines.svg";
import initialSetup from "../../assets/images/how-works/setup.svg";
import sampleData from "../../assets/images/how-works/smaple-data.svg";
import feedback from "../../assets/images/how-works/export-result.svg";
import exportResult from "../../assets/images/how-works/project-manager.svg";
import productionRun from "../../assets/images/how-works/production-run.svg";
import completeData from "../../assets/images/how-works/complete-data.svg";
import initialResults from "../../assets/images/how-works/initial-results.svg";

export const HowWorksData = [
  {
    id: 1,
    title: "Talk To a VISTA Project Manager",
    img: projectManager,
    alt: "Talk To a VISTA Project Manager",
  },
  {
    id: 2,
    title: "Share Guidelines",
    img: shareGuidlines,
    alt: "Share Guidelines",
  },
  {
    id: 3,
    title: "Initial Setup",
    img: initialSetup,
    alt: "Initial Setup",
  },
  {
    id: 4,
    title: "Sample Data",
    img: sampleData,
    alt: "Sample Data",
  },
  {
    id: 5,
    title: "Client Feedback",
    img: feedback,
    alt: "Client Feedback",
  },
  {
    id: 6,
    title: "Export Results",
    img: exportResult,
    alt: "Export Results",
  },

  {
    id: 7,
    title: "Production Run",
    img: productionRun,
    alt: "Production Run",
  },
  {
    id: 8,
    title: "Upload Complete Data",
    img: completeData,
    alt: "Upload Complete Data",
  },
  {
    id: 9,
    title: "Review Initial results",
    img: initialResults,
    alt: "Review Initial results",
  },
];
