import dataAnonymizationImg1 from "../../assets/images/data-anonymization/driver-behavior-analysis.webp";
import dataAnonymizationImg2 from "../../assets/images/data-anonymization/accident-and-incident-analysis.webp";
import dataAnonymizationImg3 from "../../assets/images/data-anonymization/usage-pattern-analysis.webp";
import dataAnonymizationImg4 from "../../assets/images/data-anonymization/fleet-management.webp";
import dataAnonymizationImg5 from "../../assets/images/data-anonymization/regulatory-compliance-and-reporting.webp";
import dataAnonymizationImg6 from "../../assets/images/data-anonymization/research-and-development.webp";
import dataAnonymizationImg7 from "../../assets/images/data-anonymization/performance-benchmarking.webp";
import dataAnonymizationImg8 from "../../assets/images/data-anonymization/customer-feedback-and-improvement.webp";
import dataAnonymizationImg9 from "../../assets/images/data-anonymization/mapping-and-localization.webp";
import dataAnonymizationImg10 from "../../assets/images/data-anonymization/simulation-and-testing.webp";

export const DataAnonymization = [
  {
    id: "driver-behavior-analysis",
    title: "Driver Behavior Analysis",
    text: "In order to improve data collection efficiency, VISTA is necessary for the smooth integration and precise calibration of sensors.",
    path: "/",
    img: dataAnonymizationImg1,
    alt: "Driver Behavior Analysis",
  },
  {
    id: "accident-and-incident-analysis",
    title: "Accident and Incident Analysis",
    text: "Reliability and efficiency in gathering and storing data from multiple sources are hallmarks of VISTA's data logging expertise. ",
    path: "/",
    img: dataAnonymizationImg2,
    alt: "Accident and Incident Analysis",
  },
  {
    id: "usage-pattern-analysis",
    title: "Usage Pattern Analysis",
    text: "VISTA simplifies intricate mapping projects and creates opportunities for industries that depend on accurate data. With the most sophisticated tools, users can navigate with assurance and dependability.",
    path: "/",
    img: dataAnonymizationImg3,
    alt: "Usage Pattern Analysis",
  },
  {
    id: "fleet-management",
    title: "Fleet Management",
    text: "VISTA seeks to provide information about the dependability and efficiency of vehicles through the use of technology and sophisticated data gathering techniques. ",
    path: "/",
    img: dataAnonymizationImg4,
    alt: "Fleet Management",
  },

  {
    id: "regulatory-compliance-and-reporting",
    title: "Regulatory Compliance and Reporting",
    text: "Researchers can simplify data collection with the help of VISTA, a Visual Interactive Scenario and Route Planning tool. ",
    path: "/",
    img: dataAnonymizationImg5,
    alt: "Regulatory Compliance and Reporting",
  },
  {
    id: "research-and-development",
    title: "Research and Development",
    text: "Annotating and labeling data sets is made efficient with VISTA. It improves accuracy and comprehensiveness by aiding in the organization and classification of data.",
    path: "/",
    img: dataAnonymizationImg6,
    alt: "Research and Development",
  },
  {
    id: "performance-benchmarking",
    title: "Performance Benchmarking",
    text: "Our knowledge of driving behaviors and patterns in diverse environments is improved by the innovative service VISTA, which gathers real driving data. By examining this data, important insights into how people make decisions when driving are obtained, which helps to create transportation systems that are safer and more effective.",
    path: "/",
    img: dataAnonymizationImg7,
    alt: "Performance Benchmarking",
  },
  {
    id: "customer-feedback-and-improvement",
    title: "Customer Feedback and Improvement",
    text: "With its immersive and interactive, needs-specific simulations, VISTA is a state-of-the-art technology that is revolutionizing training. ",
    path: "/",
    img: dataAnonymizationImg8,
    alt: "Customer Feedback and Improvement",
  },
  {
    id: "mapping-and-localization",
    title: "Mapping and Localization",
    text: "To ensure accurate and trustworthy data for well-informed decision-making, data quality assurance is essential for organizations utilizing platforms such as VISTA. ",
    path: "/",
    img: dataAnonymizationImg9,
    alt: "Mapping and Localization",
  },
  {
    id: "simulation-and-testing",
    title: "Simulation and Testing",
    text: "By helping businesses comply with laws and regulations, VISTA's Regulatory Compliance and Reporting services ensure compliance and lower the risk of penalties. ",
    path: "/",
    img: dataAnonymizationImg10,
    alt: "Simulation and Testing",
  },
];
