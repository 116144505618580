import React, { useEffect } from "react";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import { Link } from "react-router-dom";
import notFoundImg from "../assets/images/404.svg";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the homepage after 5 seconds
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Cleanup the timer if the component is unmounted before 5 seconds
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <Header />
      <div className="h-100 pb-5   d-flex justify-content-center align-items-center">
        <div>
          <div className="text-center">
            <img
              src={notFoundImg}
              style={{ width: "350px", margin: "5px auto" }}
              alt=""
            />
            <h3 className="mb-3">Page Not Found</h3>
            <p>Redirecting to the homepage... </p>
            <Link to="/" className="btn main_btn mt-3">
              Back Home
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
