import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import { Link } from "react-router-dom";

export const NewsList = () => {
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    fetch("https://webbitech.co.in/PHP-BETA/vista/api/get-news-events")
      .then((response) => response.json())
      .then((json) => setNewsList(json));
  }, []);

  const totalPages = Math.ceil(newsList.slice(1).length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const currentNews = newsList.slice(
    1 + (currentPage - 1) * itemsPerPage,
    1 + currentPage * itemsPerPage
  );

  return (
    <div>
      <Header />

      {/* Sub banner */}
      <div className="sub_banner">
        <div className="container">
          <div className="banner_content_desc">
            <h5 className="banner_subtitle">News</h5>
            <h1 className="banner_title">
              Stay Updated with VISTA's Latest News
            </h1>
            <div className="breadcrumb_detail">
              <div className="breadcrumb_box_grey">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      News
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="news_detail">
        <div className="container">
          <h5 className="news_title">Featured news</h5>
          <div className="row gy-4">
            <div className="col-lg-5">
              <div className="featured_news">
                {newsList.slice(0, 1).map((item) => {
                  return (
                    <div className="news_box card" key={item.id}>
                      <div className="card_img">
                        <img src={item.image_url} alt="" />
                      </div>
                      <div className="card_body">
                        <div className="row gy-3">
                          <div className="col-lg-3 col-2">
                            <h5 className="news_box_month">
                              {item.news_month}
                            </h5>
                            <h3 className="news_box_date">{item.month_date}</h3>
                          </div>
                          <div className="col-lg-9  col-10">
                            <h2 className="card_title">{item.title} </h2>
                            <p className="card_text">
                              {item.short_description}
                            </p>
                            <p className="card_link">
                              <Link
                                to={`/News/${item.id}`}
                                onClick={() => window.scrollTo(0, 0)}
                                className="card_link">
                                Read More{" "}
                                <i className="bi bi-chevron-right"></i>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="latest_news_list">
                <div className="row gy-4">
                  {currentNews.map((item, index) => {
                    return (
                      <div className="col-lg-12" key={index}>
                        <div className="latest_news_box">
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="card_img">
                                <img src={item.image_url} alt="" />
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <div className="card_body">
                                <h2 className="card_title">{item.title}</h2>
                                <p className="card_text">
                                  {item.short_description}
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="card_link mb-0">
                                    <Link
                                      to={`/News/${item.id}`}
                                      onClick={() => window.scrollTo(0, 0)}
                                      className="card_link">
                                      Read More{" "}
                                      <i className="bi bi-chevron-right"></i>
                                    </Link>
                                  </p>
                                  <p className="date_text mb-0">
                                    {item.news_date}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}>
                        <button
                          className="page-link prev"
                          onClick={() => handlePageChange(currentPage - 1)}>
                          <i class="bi bi-chevron-left"></i>
                        </button>
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${
                            currentPage === i + 1 ? "active" : ""
                          }`}>
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(i + 1)}>
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}>
                        <button
                          className="page-link next"
                          onClick={() => handlePageChange(currentPage + 1)}>
                          <i class="bi bi-chevron-right"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
