import electronicsEmbededImg1 from "../../assets/images/electronics-embeded/firmware-design.webp";
import electronicsEmbededImg2 from "../../assets/images/electronics-embeded/embeded-software.webp";
import electronicsEmbededImg3 from "../../assets/images/electronics-embeded/system-intergration.webp";
import electronicsEmbededImg4 from "../../assets/images/electronics-embeded/hmi-design.webp";
import electronicsEmbededImg5 from "../../assets/images/electronics-embeded/pcb-design.webp";
import electronicsEmbededImg6 from "../../assets/images/electronics-embeded/antenna-design.webp";
import electronicsEmbededImg7 from "../../assets/images/electronics-embeded/rf-wireless-design.webp";
import electronicsEmbededImg8 from "../../assets/images/electronics-embeded/system-molding.webp";
import electronicsEmbededImg9 from "../../assets/images/electronics-embeded/system-integration.webp";
import electronicsEmbededImg10 from "../../assets/images/electronics-embeded/cable-harness.webp";

export const ElectrincsEmbeded = [
  {
    id: "firmware-design",
    title: "Firmware Design",
    text: "Our expertise lies in delivering exceptional firmware design services tailored to meet the unique needs of our clients. ",
    path: "/",
    img: electronicsEmbededImg1,
    alt: "Firmware Design",
  },
  {
    id: "embedded-software-development",
    title: "Embedded Software Development",
    text: "We help businesses create software that is embedded into devices like smartphones, cars, and medical equipment. ",
    path: "/",
    img: electronicsEmbededImg2,
    alt: "Embedded Software Development",
  },
  {
    id: "system-integration-and-testing",
    title: "System Integration and Testing",
    text: "It involves integrating various subsystems into one system and thoroughly testing it to ensure functionality and reliability. ",
    path: "/",
    img: electronicsEmbededImg3,
    alt: "System Integration and Testing",
  },
  {
    id: "hmi-design",
    title: "HMI design",
    text: "We put in relentless effort to ensure that the interfaces are user-friendly, intuitive, and easy to comprehend. ",
    path: "/",
    img: electronicsEmbededImg4,
    alt: "HMI design",
  },

  {
    id: "pcb-design-fabricating",
    title: "PCB Design & fabricating",
    text: "We specialize in providing high-quality solutions for all PCB design needs, from simple circuit boards to complex projects. ",
    path: "/",
    img: electronicsEmbededImg5,
    alt: "PCB Design",
  },
  {
    id: "antena-design",
    title: "Antena Design",
    text: "We are known for our expertise in designing advanced antennas for various purposes, focusing on innovation and precision. ",
    path: "/",
    img: electronicsEmbededImg6,
    alt: "Antena Design",
  },
  {
    id: "rf-wireless-design",
    title: "RF/Wireless Design",
    text: "VISTA's RF/Wireless Design services are tailored to assist businesses in developing cutting-edge communication systems that are not only efficient but also highly reliable.",
    path: "/",
    img: electronicsEmbededImg7,
    alt: "RF/Wireless Design",
  },
  {
    id: "system-modeling",
    title: "System Modeling",
    text: "With VISTA, engineers and designers can simulate different scenarios and test out potential changes before implementing them in real life. ",
    path: "/",
    img: electronicsEmbededImg8,
    alt: "System Modeling",
  },
  {
    id: "system-integration",
    title: "System Integration",
    text: "VISTA System Integration is a process where different computer systems and software applications are combined to work together seamlessly. ",
    path: "/",
    img: electronicsEmbededImg9,
    alt: "System Integration",
  },
  {
    id: "cable-harness",
    title: "Cable Harness",
    text: "VISTA known for its excellence and innovation in design, manufacturing, installation, and maintenance. ",
    path: "/",
    img: electronicsEmbededImg10,
    alt: "Cable Harness",
  },
];
