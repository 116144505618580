import React, { useState, useRef } from "react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import { Link } from "react-router-dom";
import csrImg1 from "../assets/images/that-my-child.webp";
import csrImg2 from "../assets/images/hope-3.jpg";
import coreImg1 from "../assets/images/csr/educational-support.svg";
import coreImg2 from "../assets/images/csr/family-support.svg";
import coreImg3 from "../assets/images/csr/safe-nutrify.svg";

import communityImg1 from "../assets/images/csr/community-empowerment.jpg";
import communityImg2 from "../assets/images/csr/skill-development.jpg";
import communityImg3 from "../assets/images/csr/health-wellness.jpg";

import educationImg1 from "../assets/images/csr/education-outreach.jpg";
import educationImg2 from "../assets/images/csr/support-innovation.jpg";
import educationImg3 from "../assets/images/csr/membership-guidence.jpg";

import skillsImg1 from "../assets/images/csr/pool-formation.jpg";
import skillsImg2 from "../assets/images/csr/resource-depolyment.svg";

import imapactImg1 from "../assets/images/csr/impact-reports.svg";
import imapactImg2 from "../assets/images/csr/contineous-improvement.svg";
import imapactImg3 from "../assets/images/csr/community-feedback.svg";

import joinImg from "../assets/images/csr/join-us.webp";

export const CSR = () => {
  return (
    <div>
      <Header />
      {/* Sub banner */}
      <div className="sub_banner">
        <div className="container">
          <div className="banner_content_desc">
            <h5 className="banner_subtitle">CSR</h5>
            <h1 className="banner_title">Corporate Social Responsibility</h1>
            <div className="breadcrumb_detail">
              <div className="breadcrumb_box_grey">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      CSR
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="csr_detail">
        <div className="container">
          <h2 className="csr_title">
            Our Commitment to Corporate Social Responsibility
          </h2>
          <p className="csr_text">
            VistaES is firmly devoted to creating a good and long-term effect
            through its Corporate Social Responsibility (CSR) programs. Our
            focus is on child welfare, education, and community development, and
            we are pleased to announce official Memoranda of Understanding
            (MOUs) with [That's My Child](https://www.thatsmychild.in/) and
            [Hope3](https://www.hope3.org/). These relationships demonstrate our
            commitment to these causes, as well as our continued efforts to
            promote and improve communities.
          </p>
          <div className="mt-5">
            <div className="row gy-4 justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="card csr_box">
                  <div className="card_img">
                    <img src={csrImg1} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">That's My Child</h5>
                    <p className="card_text">
                      Good Education is not a privilege, it’s a fundamental
                      right; presenting that right to the less privileged
                      children for them to have a sustainable and bright future
                      is Thats My Child’s reason for existence.
                    </p>
                    <a
                      href="https://www.thatsmychild.in/"
                      className="card_link">
                      Read More <i class="bi bi-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card csr_box">
                  <div className="card_img">
                    <img src={csrImg2} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">Hope3</h5>
                    <p className="card_text">
                      Learning gives creativity, creativity leads to thinking,
                      thinking provides knowledge,and knowledge makes you great
                    </p>
                    <a href="https://www.hope3.org/" className="card_link">
                      Read More <i class="bi bi-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core_csr">
        <div className="container">
          <h5 className="csr_subtitle">Our Core CSR Initiatives</h5>
          <h2 className="csr_title">Empowering children and families.</h2>
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-11">
              <p className="csr_text">
                Our efforts to help children and families are wide and
                continuous. Through our collaborations and independent projects,
                we are making a major difference.
              </p>
            </div>
          </div>
          <div className="mt-5">
            <div className="row gy-4 ">
              <div className="col-lg-4 col-md-6">
                <div className="card core_box">
                  <div className="icon">
                    <img src={coreImg1} alt="" />
                  </div>
                  <h5 className="card_title">Educational Support Programs</h5>
                  <p className="card_text">
                    In partnership with That's My Child, we will offer
                    scholarships, educational materials, and tutoring to poor
                    youngsters. Our ongoing assistance will guarantee that these
                    children have the resources and chances they require to
                    excel academically and build a better future.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card core_box">
                  <div className="icon">
                    <img src={coreImg2} alt="" />
                  </div>
                  <h5 className="card_title">Family Support Services</h5>
                  <p className="card_text">
                    In partnership with Hope3, we will continually give help
                    through nutrition programs, parental education, and
                    counseling services. Our frequent interventions will assist
                    families in overcoming problems and improving their general
                    well-being.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card core_box">
                  <div className="icon">
                    <img src={coreImg3} alt="" />
                  </div>
                  <h5 className="card_title">
                    Safe and Nurturing Environments
                  </h5>
                  <p className="card_text">
                    We will intend to actively participate in the creation and
                    maintenance of safe places like as community centers and
                    after-school activities. These surroundings will provide
                    youngsters with opportunities to grow, learn, and be
                    mentored, so contributing to their overall development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core_csr1">
        <div className="container">
          <h2 className="csr_title">Community Development and Engagement</h2>
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-11">
              <p className="csr_text">
                Our community-focused projects aim to promote long-term growth
                and development. Our continuous support includes
              </p>
            </div>
          </div>
          <div className="community_list">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6">
                <div className="card community_box">
                  <div className="card_img">
                    <img src={communityImg1} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">
                      Community Empowerment Projects
                    </h5>
                    <p className="card_text">
                      Through our MOUs with That's My Child and Hope3, we will
                      participate in a variety of community development
                      initiatives. From upgrading local infrastructure to
                      assisting with environmental conservation initiatives, our
                      work meets important community needs and promotes
                      resilience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card community_box">
                  <div className="card_img">
                    <img src={communityImg2} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">
                      Skill Development and Employment
                    </h5>
                    <p className="card_text">
                      We will continue to promote skill-building programs and
                      vocational training to improve employability and
                      entrepreneurship. Our relationships have allowed us to
                      deliver more resources and knowledge, which will have a
                      real influence on people's employment opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card community_box">
                  <div className="card_img">
                    <img src={communityImg3} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">
                      Health and Wellness Initiatives
                    </h5>
                    <p className="card_text">
                      We plan to hold health camps on a regular basis, provide
                      critical healthcare services, and promote wellness through
                      various activities. Our collaborations with That's My
                      Child and Hope3 will strengthen these initiatives and
                      ensure complete support for community health.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core_csr">
        <div className="container">
          <h2 className="csr_title">Promoting Education and Innovation.</h2>
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-11">
              <p className="csr_text">
                Education and innovation are critical to long-term success, and
                our programs demonstrate this commitment
              </p>
            </div>
          </div>
          <div className="service_list">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6">
                <div className="card service_box">
                  <div className="card_img">
                    <img src={educationImg1} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">
                      Educational Outreach and Literacy:
                    </h5>
                    <p className="card_text">
                      We will offer literacy programs, digital learning
                      initiatives, and educational outreach projects. In
                      collaboration with That's My Child and Hope3, we will
                      broaden these initiatives to reach more people and
                      overcome educational inequalities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card service_box">
                  <div className="card_img">
                    <img src={educationImg2} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">Support for Innovation</h5>
                    <p className="card_text">
                      We will continue to fund innovative initiatives and
                      businesses that address educational and societal concerns.
                      Our combined resources and skills alongside our partners
                      will allow us to drive meaningful solutions while also
                      cultivating a culture of innovation and problem-solving.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card service_box">
                  <div className="card_img">
                    <img src={educationImg3} alt="" />
                  </div>
                  <div className="card_body">
                    <h5 className="card_title">Mentorship and Guidance</h5>
                    <p className="card_text">
                      We will match students and young professionals with
                      experienced mentors. This continual assistance might
                      assist individuals in developing their talents, advancing
                      their jobs, and achieving personal improvement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core_csr2">
        <div className="container">
          <h2 className="csr_title">Resource development and deployment.</h2>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-11">
              <p className="csr_text">
                Our commitment will include creating and deploying necessary
                resources to improve our operations and services
              </p>
            </div>
          </div>
          <div className="community_list">
            <div className="row gy-4">
              <div className="col-lg-6 col-md-6">
                <div className="card community_box">
                  <div className="row gx-3 align-items-center">
                    <div className="col-lg-4">
                      <div className="card_img">
                        <img src={skillsImg1} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="card_body">
                        <h5 className="card_title">Skills Pool Formation</h5>
                        <p className="card_text">
                          We will create a pool of resources with expertise in
                          CAD/CAM, meshing, analysis, embedded software,
                          annotation, data analysis, soft skills, and
                          communication skills. This resource pool will let us
                          tackle varied initiatives more effectively.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="card community_box">
                  <div className="row gx-3 align-items-center">
                    <div className="col-lg-4">
                      <div className="card_img">
                        <img src={skillsImg2} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="card_body">
                        <h5 className="card_title">Resource Deployment</h5>
                        <p className="card_text">
                          We will employ these resources for VISTA in-house
                          initiatives, our Offshore Development Center (ODC),
                          and client sites. This might ensure that our
                          initiatives benefit from highly trained individuals,
                          therefore improving both quality and efficiency.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-impact">
        <div className="container">
          <h2 className="impact_title">Measuring Our Impact</h2>
          <p className="impact_text">
            We are devoted to openness and accountability in all of our CSR
            initiatives. Our method involves
          </p>
          <div className="mt-5">
            <div className="row gy-4 align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="icon">
                  <img src={imapactImg1} alt="" />
                </div>
                <h5 className="card_title">Impact Reports</h5>
                <p className="card_text">
                  We will issue thorough yearly reports that showcase the
                  results of our efforts, as well as the continuous assistance
                  offered through our collaborations with That's My Child and
                  Hope3
                </p>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon">
                  <img src={imapactImg2} alt="" />
                </div>
                <h5 className="card_title">Continuous Improvement</h5>
                <p className="card_text">
                  We would evaluate and improve our tactics based on feedback
                  and outcomes to ensure that our activities remained successful
                  and impactful.
                </p>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon">
                  <img src={imapactImg3} alt="" />
                </div>
                <h5 className="card_title">Community Feedback</h5>
                <p className="card_text">
                  By engaging with community members and stakeholders, we can
                  better understand their needs and constantly improve our
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="join_us">
        <div className="container">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-7">
              <div className="join_left">
                <h2 className="join_title">Join Us to Make a Difference</h2>
                <p className="join_text">
                  There are several ways you may support us in our aim to effect
                  positive change:
                </p>
                <ul className="join_list">
                  <li>
                    <b>Volunteer:</b> Offer your time and skills to our programs
                    and projects. Your engagement has a tremendous impact on the
                    lives of individuals we help.
                  </li>
                  <li>
                    <b> Donate:</b> Financial and in-kind gifts help us support
                    current programs and broaden our reach. Every gift helps us
                    make a difference for the long term.
                  </li>
                  <li>
                    <b>Partner:</b> Work with us to create and implement
                    significant projects. We accept collaborations that support
                    our goal and boost our CSR activities.
                  </li>
                </ul>

                <p className="join_text">
                  With the help of our partners and the community, we can
                  continue to build a better future for children, families, and
                  communities. Join us in creating a meaningful and long-lasting
                  difference.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={joinImg} width="100%" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
