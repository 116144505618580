import React from "react";
import { Header } from "../components/layout/header/Header";
import { Footer } from "../components/layout/footer/Footer";
import { Link } from "react-router-dom";

export const ITITES = () => {
  return (
    <>
      <Header />
      {/* Sub banner */}
      <div className="sub_banner">
        <div className="container">
          <div className="banner_content_desc">
            <h5 className="banner_subtitle">IT IT/Es</h5>
            <h1 className="banner_title">IT IT/Es</h1>
            <div className="breadcrumb_detail">
              <div className="breadcrumb_box">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      IT IT/Es
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="comming-soon-page">
        <div className="container">
          <h2 className="comming-soon-title">Coming Soon...</h2>
        </div>
      </section>

      <Footer />
    </>
  );
};
