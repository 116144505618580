import sapServiceImg1 from "../../assets/images/sap-service/sap-4hana.webp";
import sapServiceImg2 from "../../assets/images/sap-service/sap-business-technology.webp";
import sapServiceImg3 from "../../assets/images/sap-service/sap-ariba.webp";
import sapServiceImg4 from "../../assets/images/sap-service/sap-digital-supply-chain.webp";
import sapServiceImg5 from "../../assets/images/sap-service/sap-financial-service.webp";
import sapServiceImg6 from "../../assets/images/sap-service/sap-customer-service.webp";
import sapServiceImg7 from "../../assets/images/sap-service/sap-cloud-program.webp";

export const SapService = [
  {
    id: "sap-s-4hana",
    title: "SAP S/4HANA",
    text: "VISTA offers comprehensive solutions to help businesses optimize operations and streamline processes, whether it's implementation, customization, or support. ",
    path: "/",
    img: sapServiceImg1,
    alt: "SAP S/4HANA",
  },
  {
    id: "sap-business-technology-platform",
    title: "SAP Business Technology Platform",
    text: "This innovative solution allows companies to harness advanced technology to stay ahead of the competition and adapt to the ever-changing business landscape.",
    path: "/",
    img: sapServiceImg2,
    alt: "SAP Business Technology Platform",
  },
  {
    id: "sap-ariba",
    title: "SAP Ariba",
    text: "SAP Ariba is a powerful software platform that streamlines procurement processes for businesses of all sizes, enabling efficient management of sourcing, procurement, and supplier activities. ",
    path: "/",
    img: sapServiceImg3,
    alt: "SAP Ariba",
  },
  {
    id: "sap-digital-supply-chain",
    title: "SAP Digital Supply Chain",
    text: "This service helps businesses streamline their supply chain processes by utilizing digital technologies. ",
    path: "/",
    img: sapServiceImg4,
    alt: "SAP Digital Supply Chain",
  },

  {
    id: "sap-financial-services",
    title: "SAP Financial Services",
    text: "Our team of experts provides comprehensive services, including budgeting assistance, investment planning, and retirement savings. ",
    path: "/",
    img: sapServiceImg5,
    alt: "SAP Financial Services",
  },
  {
    id: "sap-customer-experience-cx",
    title: "SAP Customer Experience (CX)",
    text: "VISTA is a cutting-edge SAP Customer Experience service provider that helps clients enhance customer relations and satisfaction. ",
    path: "/",
    img: sapServiceImg6,
    alt: "SAP Customer Experience (CX)",
  },
  {
    id: "sap-cloud-platform",
    title: "SAP Cloud Platform",
    text: "VISTA offers tools for businesses to efficiently manage and utilize data, leading to increased productivity. ",
    path: "/",
    img: sapServiceImg7,
    alt: "SAP Cloud Platform",
  },
];
