import digiImg1 from "../../assets/images/digital_engineering-service/advanced-fea-cfd.webp";
import digiImg2 from "../../assets/images/digital_engineering-service/manufacture-process-simulation.webp";
import digiImg3 from "../../assets/images/digital_engineering-service/infused-cad-aml.webp";
import digiImg4 from "../../assets/images/digital_engineering-service/structural-stamping.webp";
import digiImg5 from "../../assets/images/digital_engineering-service/mechanical-stamping.webp";
import digiImg6 from "../../assets/images/digital_engineering-service/sesmic-pe-stamping.webp";
import digiImg7 from "../../assets/images/digital_engineering-service/product-design-engineering.webp";
import digiImg8 from "../../assets/images/digital_engineering-service/product-lifecycle-management.webp";
import digiImg9 from "../../assets/images/digital_engineering-service/system-design-engineering.webp";
import digiImg10 from "../../assets/images/digital_engineering-service/cad-migration.webp";
import digiImg11 from "../../assets/images/digital_engineering-service/manufacture-engineering.webp";
import digiImg12 from "../../assets/images/digital_engineering-service/digital-transformation.webp";
import digiImg13 from "../../assets/images/digital_engineering-service/robotic-proces-management.webp";
import digiImg14 from "../../assets/images/digital_engineering-service/ar-virtual-reality.webp";
import digiImg15 from "../../assets/images/digital_engineering-service/cyber-secruity.webp";

export const DigitalEngineeringServiceData = [
  {
    id: "advanced-fea-cfd-simulation",
    title: "Advanced FEA/CFD Simulation",
    text: "VISTA offers complex Multiphysics Simulation services including FEA and CFD. These services enable engineers and designers to test and assess their products before they are manufactured. ",
    img: digiImg1,
    alt: "Advanced FEA/CFD Simulation",
  },
  {
    id: "manufacturing-process-simulation",
    title: "Manufacturing Process Simulation",
    text: "To stay relevant in today's industrial environment, companies must innovate and enhance their manufacturing processes.  ",
    img: digiImg2,
    alt: "Manufacturing Process Simulation",
  },
  {
    id: "infuse-ai-ml-into-cad-cae-automation",
    title: "Infuse AI/ML into CAD/CAE Automation",
    text: "We infuse AI/ML into CAD/CAE automation that minimize redundant tasks such as retrofit design, meshing, solving, post processing, drafting, report generation and documentation tasks and saves up to 95% of the time. ",
    img: digiImg3,
    alt: "Infuse AI/ML into CAD/CAE Automation",
  },
  {
    id: "structural-engineering-pe-stamping",
    title: "Structural Engineering PE Stamping",
    text: "VISTA Structural Engineering team members are licensed to all 50 states in the USA and have completed more than 500 turnkey projects from 20 countries worldwide.",
    img: digiImg4,
    alt: "Structural Engineering PE Stamping",
  },

  {
    id: "mechanical-pe-stamping",
    title: "Mechanical PE Stamping",
    text: "VISTA Mechanical PE's can check the hand calculations against CAE tools and optimize the design without compromising the minimum code requirement. ",
    img: digiImg5,
    alt: "Mechanical PE Stamping",
  },
  {
    id: "seismic-pe-stamping",
    title: "Seismic PE stamping",
    text: "VISTA helps clients every step of the way through the PE stamping process, from initial document preparation to final regulatory approval, with a team of highly skilled and licensed SE professionals.  ",
    img: digiImg6,
    alt: "Seismic PE stamping",
  },
  {
    id: "product-design-engineering",
    title: "Product Design Engineering",
    text: "Using the most recent developments in CAD software, 3D printing, and rapid prototyping technologies, our team of extremely talented engineers, designers, and project managers collaborate closely to take on even the most difficult design challenges. ",
    img: digiImg7,
    alt: "Product Design Engineering",
  },
  {
    id: "product-lifecyle-management",
    title: "Product Lifecyle Management",
    text: "Product Lifecycle Management (PLM) technology services are offered by VISTA, a prominent supplier.  ",
    img: digiImg8,
    alt: "Product Lifecyle Management",
  },
  {
    id: "system-design-and-engineering",
    title: "System Design and Engineering",
    text: "In order to offer state-of-the-art technology services to a wide range of clients, a system design that is painstakingly engineered and capable of smoothly delivering solutions that are robust, dependable, and scalable is necessary.  ",
    img: digiImg9,
    alt: "System Design and Engineering",
  },
  {
    id: "cad-migration-services",
    title: "CAD Migration Services",
    text: "With the aid of VISTA's CAD Migration services, companies can easily move CAD data between software platforms. ",
    img: digiImg10,
    alt: "CAD Migration Services",
  },
  {
    id: "manufacturing-engineering",
    title: "Manufacturing Engineering",
    text: "provides a full range of Manufacturing Engineering solutions to help businesses improve operational effectiveness and streamline their production processes.  ",
    img: digiImg11,
    alt: "Manufacturing Engineering",
  },
  {
    id: "digital-transformation",
    title: "Digital Transformation",
    text: "Whether a client wants to reinvent their customer experience, modernize legacy systems, or take advantage of emerging technologies, VISTA's approach is based on a thorough understanding of the distinct opportunities and challenges that each one faces.  ",
    img: digiImg12,
    alt: "Digital Transformation",
  },
  {
    id: "robotic-process-automation-rpa",
    title: "Robotic Process Automation (RPA)",
    text: "VISTA's cutting-edge RPA platform, uses intelligent software bots to automate a variety of repetitive, rule-based tasks throughout an organization. ",
    img: digiImg13,
    alt: "Robotic Process Automation (RPA)",
  },
  {
    id: "augmented-reality-and-virtual-reality",
    title: "Augmented Reality and Virtual Reality",
    text: "For training, simulation, and design visualization, we specialize in producing immersive experiences.  ",
    img: digiImg14,
    alt: "Augmented Reality and Virtual Reality",
  },
  {
    id: "cybersecurity",
    title: "Cybersecurity",
    text: "VISTA assesses a system's security posture and creates a strategy for putting robust security measures in place.  ",
    img: digiImg15,
    alt: "Cybersecurity",
  },
];
